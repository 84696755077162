import BSN from "bootstrap.native";
import {OrdersController} from "../orders_controller";
import Tabulator from "tabulator-tables";

export default class extends OrdersController {
  static targets = [
    // filters
    "search", "number", "client_number", "status", "from_city_ids", "to_city_ids", "start_date", "end_date",
    // tabs and tables
    "active_table",
    // mapping
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    if (this.hasActive_tableTarget) {
      this._crateActiveTable().then(() => {
        console.log('Active table was successfully created.');
      }).catch(error => {
        console.error('Active table Error:', error);
      });
    }
  }

  //
  // Actions
  //
  // table filters action
  filterTable(event) {
    if (event.currentTarget === this.searchTarget) {
      if ( event.currentTarget.value.length < 1 && event.currentTarget.value.length !== 0 ) {
        return;
      }
    }

    let filters = this._getPageFilters();
    
    // Change dataset filtersChanged to true
    super._filtersChangedForTable(this.active_tableTarget);

    this._filterActiveTable(filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });

    if (filters.length > 0) {
      this._filtersToHistoryUrl(filters);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }
  //
  //            PRIVATE
  //
  async _filterActiveTable(filters) {
    let activeTabulator = this.active_tableTarget.tabulator;
    if (!activeTabulator) return;

    await this._setFilterToTable(activeTabulator, filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
  }

  async _crateActiveTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("active-table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_orders_active_table_" + lang;

    await this._createOrdersTable(
      url,
      this.active_tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  async _createOrdersTable(url, table_target, persistenceID, columns, sort_column, sort_dir, filters) {
    let props = {
      columns: columns,
      persistenceID: persistenceID,
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: filters,
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.start_today === true) {
          row.getElement().classList.add("order_today");
        }
      },
    }
    //
    let table_props = Object.assign(this._getDefaultTableProps(url), props);

    // create table
    table_target.tabulator = await new Tabulator(table_target, table_props);
    await table_target.tabulator.setLocale(super.appLocale);
  }

}