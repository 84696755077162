import {OrdersController} from "../../orders_controller";
import { getMetaValue } from "helpers";
import Choices from "choices.js";

const TABLE_ID = 'order_itinerary_form_table';
const RELOAD_DELAY = 1500; // Delay before page reload after showing notification
export default class extends OrdersController {
  static targets = [
    'is_manual',
    "service_id",
    "service_name",
    "choices_order_services_vehicle_search",
    "choices_order_services_driver_search"
  ]

  initialize() {
    if (this.hasChoices_order_services_vehicle_searchTarget) { 
        this.orderServicesAjaxChoise(this.choices_order_services_vehicle_searchTarget, 'vehicle'); 
        return; 
    }
    if (this.hasChoices_order_services_driver_searchTarget){ 
      this.orderServicesAjaxChoise(this.choices_order_services_driver_searchTarget, 'driver'); 
      return; 
    }
  }

  toggleManualFields(event) {
    event.preventDefault();
    let enabled = event.currentTarget.checked;
    const tr = event.currentTarget.closest("tr");
    let service_select_div = tr.querySelector(".service-select-form-group .select-field");
    let service_select = service_select_div.querySelector("select");
    let service_input = tr.querySelector(".service-select-form-group .input-field input");

    if (enabled) {
      service_select.choices.disable();
      service_select_div.classList.add('hidden');

      service_input.removeAttribute('disabled');
      service_input.classList.remove('hidden');
    } else {
      service_select.choices.enable();
      service_select_div.classList.remove('hidden');

      service_input.setAttribute('disabled', 'disabled');
      service_input.classList.add('hidden');
    }
  }

  removeField(event) {
    event.preventDefault();

    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      let tr = event.currentTarget.closest("tr")
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      tr.querySelectorAll('td:not(.button)').forEach( el => el.remove() );
      tr.classList.add("hidden");

      this.reindexFormTableRows()
    }
  }


  newService(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_xl, data);
      const content = super._orderContent;
      if (content) content.innerHTML = data.order_content;
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // Clear previous errors first
      this.clearAllErrors();
      
      // If we have validation errors from our availability validator
      if (data.errors) {
        this.displayAvailabilityErrors(data.errors);
        super._errorNotify(data.notification_title, data.notification_message);
      } else {
        super._errorNotify(data.notification_title, data.notification_message);
        if (data.form_content) {
          window.layout_modal_md.setContent(data.form_content);
        }
      }
    }
  }
  
  // Display availability errors using the format from the working example
  displayAvailabilityErrors(errors) {
    Object.keys(errors).forEach(fieldName => {
      const error = errors[fieldName];
      const field = document.querySelector(`[name="${fieldName}"]`);
      
      if (field) {
        // For debugging
        console.log(`Field found: ${fieldName}`);
        
        // Mark the field as invalid
        field.classList.add('is-invalid');
        
        // Add error class to choices container if applicable
        const choicesContainer = field.closest('.choices');
        if (choicesContainer) {
          choicesContainer.classList.add('error');
          
          // Highlight the td cell itself (parent of the choices container)
          const tdCell = choicesContainer.closest('td');
          if (tdCell) {
            tdCell.classList.add('error');
          }
          
          // Add error class to the inner input and dropdown
          const innerInput = choicesContainer.querySelector('.choices__inner');
          if (innerInput) {
            innerInput.classList.add('is-invalid', 'border-danger');
          }
        }
        
        // Determine which row this field is in
        const row = field.closest('tr');
        if (row) {
          // Add a row indicator class
          row.classList.add('conflict-row');
       
          // Highlight time fields with a warning color if this is a driver or vehicle field
          if (fieldName.includes('driver_id') || fieldName.includes('vehicle_id')) {
            const dateInput = row.querySelector('.date-select-form-group input');
            const timeFromInput = row.querySelector('.time-from-select-form-group input');
            const timeToInput = row.querySelector('.time-to-select-form-group input');
            
            if (dateInput) dateInput.classList.add('is-invalid', 'border-warning');
            if (timeFromInput) timeFromInput.classList.add('is-invalid', 'border-warning');
            if (timeToInput) timeToInput.classList.add('is-invalid', 'border-warning');
          }
        }
        
        // Add error message
        const fieldContainer = field.closest('.vehicle-select-form-group') || field.closest('.driver-select-form-group');
        if (fieldContainer && !fieldContainer.querySelector('.error-message')) {

          // insert into the child .choices
          fieldContainer.insertAdjacentHTML('beforeend', 
            `<span class="error-message text-danger">${error.message}</span>`);
        }
      } else {
        // For debugging
        console.log(`Field not found: ${fieldName}`);
      }
    });
  }
  
  // Clear all validation errors
  clearAllErrors() {
    const table = document.getElementById(TABLE_ID);
    if (!table) return;
    
    // Remove all error messages
    const errorMessages = table.querySelectorAll('.error-message');
    errorMessages.forEach(el => el.remove());
    
    // Remove error class from td containers
    const errorGroups = table.querySelectorAll('.vehicle-select-form-group.error, .driver-select-form-group.error');
    errorGroups.forEach(el => el.classList.remove('error'));
    
    // Remove error class from choices containers
    const errorChoices = table.querySelectorAll('.choices.error');
    errorChoices.forEach(el => el.classList.remove('error'));
    
    // Remove error classes from inner inputs
    const errorInnerInputs = table.querySelectorAll('.choices__inner.is-invalid');
    errorInnerInputs.forEach(el => {
      el.classList.remove('is-invalid', 'border-danger');
    });
    
    // Remove error classes from date/time inputs
    const invalidDateTimeInputs = table.querySelectorAll('input.is-invalid');
    invalidDateTimeInputs.forEach(el => {
      el.classList.remove('is-invalid', 'border-warning');
    });
    
    // Remove conflict row class
    const conflictRows = table.querySelectorAll('.conflict-row');
    conflictRows.forEach(row => {
      row.classList.remove('conflict-row');
    });
  }

  async addNewService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
    }
  }

  async addServiceRequest(url) {
    const data = new FormData();

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/javascript",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      return response.text()
    }).then(text => {
      const table = document.getElementById(TABLE_ID);
      table.tBodies[0].insertAdjacentHTML("beforeend", text);
      this.reindexFormTableRows()
    })
  }

  reindexFormTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }


  /// order service choices ///

  clearTransportChoices(event) {
    const tr = event.currentTarget.closest("tr");
    const vehicle_select = tr.querySelector(".vehicle-select-form-group select");
    const driver_select = tr.querySelector(".driver-select-form-group select");

    this.clearChoices(vehicle_select);
    this.clearChoices(driver_select);
  }

  clearChoices(target_field) {
    let target_field_choice = target_field.choices;

    target_field_choice.clearInput();
    target_field_choice.clearStore();
  }


  /// choices ///

  orderServicesAjaxChoise(target, select_type) {
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let reload_datas = this.getAjaxSearchData

    let choice = new Choices(target, {
      removeItemButton: true,
      removeItems: true,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });

    target.choices = choice;

    choice.passedElement.element.addEventListener(
      "showDropdown",
      function (event) {
        reload_datas(this, '', select_type);
      },
      false,
    );

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_datas(this, event.detail.value, select_type);
      },
      false,
    );

    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)

    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  async getAjaxSearchData(target, q, search_type,) {
    const table = document.getElementById(TABLE_ID);
    const search_url = table.getAttribute("data-search-url");

    const tr = target.closest("tr");
    let date_value = tr.querySelector(".date-select-form-group input").value;
    let time_from_value = tr.querySelector(".time-from-select-form-group input").value || "00:00";
    let time_to_value = tr.querySelector(".time-to-select-form-group input").value || "23:59";
    let vechicle_category_id = tr.querySelector(".vehicle-category-select-form-group select").value;

    if (!date_value)
      return;

    let params = {
      search: q,
      date: date_value,
      time_from: time_from_value,
      time_to: time_to_value,
      vechicle_category_id: vechicle_category_id
    }

    const search_params = new URLSearchParams(params);
    const url = `${search_url}?${search_params.toString()}&search_type=${search_type}`;

    let choice = target.choices;

    await fetch(url)
      .then(res => res.json())
      .then((data) => {
        choice.setChoices(data, 'value', 'label', true)
      });
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }


  setItemsWidthToMaxWidth(html_element) {
    const choose_div = html_element.closest(".choices")
    let choices_object = html_element.choices

    choices_object.passedElement.element.addEventListener(
      'showDropdown',
      function (event) {
        const choices_dropdown = choose_div.querySelector(".choices__list--dropdown")
        const items = choices_dropdown.getElementsByClassName('choices__item--selectable')
        if (items.length > 0) {
          const widths = [].slice.call(items).map(function (div) { return div.getBoundingClientRect().width; });
          const maxWidth = Math.max.apply(null, widths);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.style.width = maxWidth + "px";
          }
        }
      },
      false,
    );
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }
  
  // Handle status update click
  updateStatus(event) {
    // Get button and data
    const button = event.currentTarget;
    const url = button.dataset.url;
    const status = button.dataset.status;
    const originalText = button.dataset.originalText;
    const loadingText = button.dataset.loadingText;
    
    // Save original button state
    button.disabled = true;
    const originalHtml = button.innerHTML;
    button.innerHTML = button.innerHTML.replace(originalText, loadingText);
    
    // Create form data
    const formData = new FormData();
    formData.append('status', status);
    
    // Get CSRF token
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    
    // Helper function to restore button state
    const restoreButton = () => {
      button.disabled = false;
      button.innerHTML = originalHtml;
    };
    
    // Make AJAX request
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'application/json'
      },
      body: formData
    })
    .then(response => {
      // Parse JSON regardless of status code
      return response.json().then(data => {
        return { status: response.status, data };
      });
    })
    .then(({ status, data }) => {
      console.log('Response:', status, data);
      
      // Important: Check explicitly for API notification error
      // data.error is true when there's an API notification error
      if (status >= 400 || data.error) {
        // Show error notification
        super._errorNotify(data.notification_title, data.notification_message);
        
        // Always restore button state on error
        restoreButton();
      } else {
        // Show success notification
        super._successNotify(data.notification_title, data.notification_message);
        
        // Wait for notification to show before reloading
        setTimeout(() => {
          window.location.reload();
        }, RELOAD_DELAY);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      super._errorNotify('Error', 'An unexpected error occurred');
      
      // Restore button state on any error
      restoreButton();
    });
  }
}