import {OrdersController} from "../../orders_controller";
import BSN from "bootstrap.native";
import Tabulator from "tabulator-tables";

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "transportOrderServicesTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("transport-order-itinerary-table")
    if (table)
      return table.getAttribute("data-transport--orders--services--index-total-text")
  },

  "transportOrderServicesTotalsValues": function (values, data, calcParams) {
    const table = document.getElementById("transport-order-itinerary-table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total");
    return total_html;
  }
});
export default class extends OrdersController {
  static targets = ["content", "itinerary_table"]

  connect() {
    if (this.hasContentTarget) {
      const content_url = this.data.get("content-load-url");
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {
        controller._createContent(content_url)
          .catch(error => console.error('Error:', error));
      });
    }

    if (this.hasItinerary_tableTarget) {
      this._createItineraryTable();
    }
  }

  // PRIVATE
  async _createContent(url) {
    this.contentTarget.innerHTML = this.spinner;
    let content_response = await fetch(url);
    this.contentTarget.innerHTML = await content_response.text();
  }

  get spinner() {
    return `
      <div class="text-center mt-50">
        <div class="loader"></div>
      </div>
    `;
  }

  async _createItineraryTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const table_target = this.itinerary_tableTarget;

    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    const url = this.data.get("table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_orders_services_itinerary_table_" + lang;

    let props = {
      layout: "fitDataTable",
      columns: columns,
      persistenceID: persistenceID,
      index: "id",
      movableColumns: true,
      height: (height - 220) + "px",
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      paginationDataSent: {
        "size": "per_page"
      },
      pagination: false,
      ajaxURL: url,
      ajaxSorting: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("transport-order-itinerary-table")
        table.setAttribute("data-total", response.total);
        return response;
      }
    }
    //
    let table_props = Object.assign(this._getDefaultTableProps(url), props);

    // create table
    table_target.tabulator = await new Tabulator(table_target, table_props);
    await table_target.tabulator.setLocale(super.appLocale);    
  }    

} 