import { TransportMainController } from "../transport_main_controller";

export class OrdersController extends TransportMainController {

  // Driver
  _reloadDriverTab() {
    const index_controller = this.application.getControllerForElementAndIdentifier(
      document.getElementById('order_tab_drivers'), "transport--orders--drivers--index"
    );

    if (index_controller) {
      const url = index_controller.data.get("content-load-url");
      index_controller._createContent(url);
    }
  }

  // Vehicles
  _reloadVehicleTab() {
    const index_controller = this.application.getControllerForElementAndIdentifier(
      document.getElementById('transport--dispatchers-vehicles-table'), "transport--orders--vehicles--index"
    );

    if (index_controller) {
      const url = index_controller.data.get("content-load-url");
      index_controller._createContent(url);
    }
  }

  // dispatchers vehicles
  _reloadDispatchersVehicleTabulator() {
    const table = document.getElementById('transport--dispatchers-vehicles-table');
    if (table.tabulator) {
      table.tabulator.setData();
    }
  }

    // dispatchers drivers
  _reloadDispatchersDriverTabulator() {
    const table = document.getElementById('transport--dispatchers-drivers-table');
    if (table.tabulator) {
      table.tabulator.setData();
    }
  }

  // PreferredCar
  addVehicleMakeRequestToForm() {
    const make_id = this.preferred_car_models_make_idTarget.choices.getValue(true);
    const make_count = this.preferred_car_models_countTarget.value;
    const make_name = this.preferred_car_models_make_idTarget.choices.getValue().label;
    this.buildOrUpdatePreferredCarModelsValue(make_id, make_count, make_name);
    this.buildOrUpdatePreferredCarModelsUl();
  }

  buildOrUpdatePreferredCarModelsUl() {
    let current_value = this.preferred_car_modelsTarget.value;
    if (!current_value) { return; }
    current_value = JSON.parse(current_value);
    let ul_html = "";
    current_value.forEach((item) => {
      ul_html += `<li class="list-group-item d-flex justify-content-between align-items-center">
        ${item.make_name}
        <span>${item.count}</span>
        <span class="btn-link text-danger ml-4" data-make-id="${item.make_id}" data-action="click->transport--orders--create#removePreferredCarModel">            
          <i class="fa fa-trash"></i>
        </span>
      </li>`;
    });
    this.preferred_car_models_ulTarget.innerHTML = ul_html;
  }

  addEditVehicleMakeRequestToForm() {
    const make_id = this.preferred_car_models_make_idTarget.choices.getValue(true);
    const make_count = this.preferred_car_models_countTarget.value;
    const make_name = this.preferred_car_models_make_idTarget.choices.getValue().label;
    this.buildOrUpdatePreferredCarModelsValue(make_id, make_count, make_name);
    this.buildEditOrUpdatePreferredCarModelsUl();
  }

  buildEditOrUpdatePreferredCarModelsUl() {
    let current_value = this.preferred_car_modelsTarget.value;
    if (!current_value) { return; }
    current_value = JSON.parse(current_value);
    let ul_html = "";
    current_value.forEach((item) => {
      ul_html += `<li class="list-group-item d-flex justify-content-between align-items-center">
        ${item.make_name}
        <span>${item.count}</span>
        <span class="btn-link text-danger ml-4" data-make-id="${item.make_id}" data-action="click->transport--orders--update#removePreferredCarModel">            
          <i class="fa fa-trash"></i>
        </span>
      </li>`;
    });
    this.preferred_car_models_ulTarget.innerHTML = ul_html;
  }

  buildOrUpdatePreferredCarModelsValue(make_id, make_count, make_name) {
    let current_value = this.preferred_car_modelsTarget.value;
    if (!current_value) { current_value = "[]"; }
    current_value = JSON.parse(current_value);
    let found = false;
    current_value = current_value.map((item) => {
      if (item.make_id === make_id) {
        found = true;
        item.count = parseInt(item.count) + parseInt(make_count);
      }
      return item;
    });
    if (!found) {
      current_value.push({make_id: make_id, count: make_count, make_name: make_name});
    }
    current_value = JSON.stringify(current_value);
    this.preferred_car_modelsTarget.value = current_value;
  }

  validatePreferredCarForm() {
    const preferred_car_models_make_id = this.preferred_car_models_make_idTarget.choices.getValue(true);
    const preferred_car_models_count = this.preferred_car_models_countTarget.value;
    let errors = [];
    if (!preferred_car_models_make_id) {
      errors.push("Выберите марку");
    }
    if (!preferred_car_models_count) {
      errors.push("Введите количество");
    }
    if (errors.length > 0) {
      this.preferred_car_models_errors_textTarget.innerHTML = errors.join("<br>");
      this.preferred_car_models_errorsTarget.classList.remove("hidden");
    } else {
      this.preferred_car_models_errors_textTarget.innerHTML = "";
      this.preferred_car_models_errorsTarget.classList.add("hidden");
    }
    return errors.length === 0;
  }

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalXL(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _openServicesModalForm(url) {
    super.openLayoutModalMD(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _sidebarTableUpdateOrAddData(row_data) {
    let table = this._sidebarTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _activeTableUpdateOrAddData(row_data) {
    let table = this._activeTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _updateOrAddDataToTable(table_type, data) {
    if (table_type === 'sidebar') {
      this._sidebarTableUpdateOrAddData(data.sidebar_row_data);
    } else {
      this._activeTableUpdateOrAddData(data.row_data);
    }
  }

  //
  // Private
  //
  get _sidebarTabulator() {
    let table_target = document.getElementById("transport--orders-sidebar-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _activeTabulator() {
    let table_target = document.getElementById("transport--orders-active-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _orderContent() {
    const content =  document.getElementById("order-show-content");
    if (content) {
      return content;
    }
  }

  // Filters
  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasNumberTarget) {
      filters.push({
        field: "number",
        type: "=",
        value: this.numberTarget.value
      })
    }

    if (this.hasClient_numberTarget) {
      filters.push({
        field: "client_number",
        type: "=",
        value: this.client_numberTarget.value
      })
    }

    if (this.hasFrom_city_idsTarget) {
      filters.push({
        field: "from_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.from_city_idsTarget)
      })
    }

    if (this.hasTo_city_idsTarget) {
      filters.push({
        field: "to_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.to_city_idsTarget)
      })
    }

    if (this.hasStart_dateTarget) {
      filters.push({
        field: "start_date",
        type: "=",
        value: this.start_dateTarget.value
      })
    }

    if (this.hasEnd_dateTarget) {
      filters.push({
        field: "end_date",
        type: "=",
        value: this.end_dateTarget.value
      })
    }

    let statusValues  = [];

    this.statusTargets.forEach((status) => {
      if (status.checked) {
        statusValues.push(status.value);
      }
    });

    if (statusValues.length > 0) {
      filters.push({
        field: "statuses",
        type: "=",
        value: statusValues
      })
    }


    return filters;
  }

}