import moment from "moment/moment";
import {buildExportUrl, exportTableXlsx} from "../../../helpers/tabulator_export_helper";
import {OrdersController} from "../orders_controller";

export default class extends OrdersController {
  // Actions
  exportTable(event) {
    event.stopPropagation();

    this._exportTableXlsx();
  }

  exportItineraryList(event) {
    event.stopPropagation();
    const url = event.currentTarget.getAttribute("data-url");
    this._exportItineraryXlsx(url);
  }

  // Private

  // export
  // Export from tabulator
  _exportTableXlsx() {
    let tableTabulator = super._activeTabulator;
    let url = buildExportUrl(tableTabulator);
    let title = "Orders - " + moment().format("YYYY-MM-DD_HH-mm-ss");

    exportTableXlsx(url, tableTabulator, title);
  }

  async _exportItineraryXlsx(url) {
    let table_target = document.getElementById("transport-order-itinerary-tabulator-table"); 

    let ids = [];
    if (table_target && table_target.tabulator) {
      let tabulator = table_target.tabulator;
      const selectedData = tabulator.getSelectedData();

      if (selectedData.length > 0) {
        for (let i = 0; i < selectedData.length; i++) {
          let row = selectedData[i];
          if (Array.isArray(row.id)) ids.push(row.id[0])
          else ids.push(row.id)
        }
      }
    }

    let url_params = new URLSearchParams();
    url_params.append("ids", ids.join(","));
    url = url + "?" + url_params.toString();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(response => {
      return response.blob();
    }).then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = "Itinerary - " + moment().format("YYYY-MM-DD_HH-mm-ss") + ".xlsx";
      a.click();
    }).catch(error => {
      console.error('Error:', error);
    });      

  }

}